import api from './api';

class TagService {
  getAll(batchId) {
    return api.get(`tags?batchId=${batchId}`);
  }
  getBatches() {
    return api.get(`tags/batches`);
  }
  get(id) {
    return api.get(`tags/${id}`);
  }

  create(data) {
    const req = {};
    if (data.customId !== '') {
      req.customId = data.customId;
    } else if (data.max !== '' && data.min !== '') {
      req.max = Number(data.max);
      req.min = Number(data.min);
    } else if (data.quantity !== '') {
      req.quantity = Number(data.quantity);
    }
    return api.post('tags', req);
  }
}

export default new TagService();
